<template>
    <section v-if="getActiveStore.role != 'STAFF'">
        <div class="panel">
            <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                <div class="columns is-mobile">
                    <div class="column is-size-6 has-text-centered">SALES BY DEPARTMENT ({{ getSetting('profitCalculationMethod') }})</div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4">
                    <date-range-selector
                        :dateRange="salesDateRange"
                        @set-date-range="setDateRange"
                        >
                    </date-range-selector>                    
                </div>
                <div class="column is-8">
                    <!-- <calculator-merchandise-price></calculator-merchandise-price> -->
                </div>

            </div> 


            <div class="panel-block">
            <b-table class="control has-text-weight-semibold" 
                :data='departmentSales.departmentSalesSummaryList'
                :paginated="isPaginated"
                :per-page="perPage"
                paginationPosition="both"
                sortIcon="arrow-up"
                striped
                sortIconSize="is-small"
                default-sort=department
                defaultSortDirection='asc'
                >

                <template>

                    <b-table-column field="department" label="Department" searchable sortable sorticon v-slot="props">
                        {{ props.row.department }}
                    </b-table-column>

                    <b-table-column field="storeMapping" label="Mapping" searchable sortable sorticon v-slot="props">
                        {{ props.row.storeMapping }}
                    </b-table-column>

                    <b-table-column numeric field="netSalesValue" label="Net Sales" sortable sorticon v-slot="props">
                        {{ props.row.netSalesValue |formatCurrency}}
                    </b-table-column>

                    <b-table-column numeric field="percentage" label="Percentage" sortable sorticon v-slot="props">
                        {{ props.row.percentage }}%
                    </b-table-column>

                    <b-table-column numeric field="cost" label="Cost" sortable sorticon v-slot="props">
                        <!-- {{ props.row.cost | formatCurrency}} -->
                        {{ calculateMercandiseCost(props.row.netSalesValue, props.row.percentage) | formatCurrency}}
                    </b-table-column>

                    <b-table-column numeric field="profit" label="Profit" sortable sorticon v-slot="props">
                        {{ props.row.netSalesValue - calculateMercandiseCost(props.row.netSalesValue, props.row.percentage) | formatCurrency }}
                    </b-table-column>

                </template>
            </b-table>

        </div>

        <div class="panel-heading has-text-weight-bold">
            <div class="columns">
                <div class="column has-text-left is-6">          
                    <div>

                    </div>
                </div>
                <div class="column has-text-centered is-2">          
                    <div>
                        <p class="is-size-7">Total Sales</p>
                        <p class="is-size-6"> {{ totalSales | formatCurrency}}</p>
                    </div>
                </div> 

                <div class="column has-text-centered is-2">          
                    <div>
                        <p class="is-size-7">Total Cost</p>
                        <p class="is-size-6">{{ totalCost | formatCurrency }} </p>
                    </div>
                </div>

                <div class="column has-text-centered is-2">          
                    <div>
                        <p class="is-size-7">Total Profit</p>
                        <p class="is-size-6">{{ totalProfit | formatCurrency }} </p>
                    </div>
                </div>
            </div>
        </div>
        
        </div>
    </section>
</template>

<script>

import UserStore from "../../../store/UserStore"
import axios from 'axios'
import DateRangeSelector from "../../../components/app/DateRangeSelector"
// import CalculatorMerchandisePrice from "../../../components/app/CalculatorMerchandisePrice"
 
export default {


    data() {
        return {
            perPage: 20,
            showPriceCalculator: false,
            isPaginated: true,
            departmentSales: {
                "departmentSalesSummaryList": []
            },
            isLoading: false,
            salesDateRange: [new Date(), new Date()],
        }
    },

    components: {
        DateRangeSelector,
        // CalculatorMerchandisePrice
    },

    metaInfo: {
        title: 'Sales by Department'
    },

    methods: {

        fetchDeptSalesSummary() {

            this.isLoading = true
            // this.salesDateRange = []

            var startDate = this.salesDateRange[0].toISOString().split('T')[0]
            var endDate = this.salesDateRange[1].toISOString().split('T')[0]

            const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore +
                        "/salessummary/department/?" +
                        "mode=DAILY&fromDate="+startDate+"&toDate="+endDate

            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                    this.departmentSales = response.data.data

                })
                .catch( (err) => {
                    this.isLoading = false
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                    this.isLoading = false
                    if (err.response.status === 403) {
                        alert('Session Expired. Please log in again!')
                        UserStore.commit('userLogout')
                    }
                })

        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },
        
        togglePriceCalculator() {
            this.showPriceCalculator != this.showPriceCalculator
        }

    },

    computed: {

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        totalSales() {
            return this.departmentSales.departmentSalesSummaryList.reduce((accum,item) => accum + parseFloat(item.netSalesValue), 0)
        },

        totalCost() {
            return this.departmentSales.departmentSalesSummaryList.reduce((accum,item) => accum + parseFloat(item.cost), 0)
        },

        totalProfit() {
            return this.departmentSales.departmentSalesSummaryList.reduce((accum,item) => accum + parseFloat(item.profit), 0)
        },

    },

    watch: {
        salesDateRange: function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.fetchDeptSalesSummary()
            } else {
                this.isLoading = false
            }
        }

    },

    mounted() {

        this.user = this.getUser()
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.salesDateRange=[firstDay, lastDay]        
    }    



    
}
</script>